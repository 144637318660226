@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}


/* Navbar start */
.logo {
    float: left;
    padding: 1.25rem 0rem;
    z-index: 50;
}

.logo-img {
    width: 2.25rem;
    cursor: pointer;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: black;
}

.menu-link {
    display: none;
    font-family: 'Poppins';
    text-transform: uppercase;
}

.link {
    position: relative;
    width: 100%;
    padding-right: 0.75rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    display: inline-block;
    font-size: 1rem;

}

.menu-item {
    padding-left: 0.75rem;
    padding-right: 1.75rem;
    cursor: pointer;
    text-align: left;
}

.absolute {
    position: absolute;
    top: 2.5rem;
    visibility: hidden;
    margin-top: 1.5rem;
    transform: translateY(20px);
}

.font-semibold {
    font-weight: 600;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-gray-600 {
    color: rgb(75, 94, 99);
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.my-2 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem
}

.text-primary:hover {
    color: rgba(0, 0, 0, 0.45);
}

h1 {
    font-size: inherit;
    font-weight: inherit;
}

.mobile-menu {
    position: fixed;
    bottom: 0px;
    top: 60px;
    padding-top: 1rem;
    padding-bottom: 6rem;
    padding-left: 1rem;
    background-color: #d6d6d6;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transition-duration: 500ms;
}

.mobile-menu.left-0 {
    left: 0;
}

.mobile-menu.left--100 {
    left: -100%;
}

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    padding: 1rem 1.725rem;

    cursor: pointer;
}

.sublink {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #333;
}

.hidden {
    display: none;
}

.display {
    display: block;
}

@media only screen and (min-width: 900px) {

    /* .logo{
        width: auto;
    } */
    .menu-icon {
        display: none;
    }

    .menu-link {
        display: flex;
        align-items: center;
        gap: 2rem;

    }

    .menu-item {
        padding-right: 0.75rem;
    }

    .menu-item:hover .absolute {
        visibility: visible;
        transform: translateY(0);
    }

    .mobile-menu {
        display: none;
    }

    .display {
        display: none;
    }

    .link {
        padding-left: 0;
        padding-right: 0;
    }

    .link::after {
        content: "";
        display: block;
        left: 0;
        right: 0;
        min-width: 100%;
        width: auto;
        background-color: #000;
        position: absolute;
        top: 70%;
        height: 2px;
        opacity: 0;
        visibility: visible;
        transform: scaleX(0);
        transition: transform 300ms;
    }

    .link:hover::after {
        opacity: 1;
        transform: scaleX(1);
    }

}

/* Navbar end */

/* Slider CSS start */
.vertical-slider {
    width: 70px;
}

.vertical-slider .slick-slider .slick-list {
    /* height: 535px; */
    overflow: hidden
}

.vertical-slider .slick-arrow {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 0;
    z-index: 1;
    color: #333;
    background: linear-gradient(to bottom, #fff, rgba(80, 92, 95, 0.479));
}

.vertical-slider .slick-prev {
    background: linear-gradient(to top, #fff, rgba(80, 92, 95, 0.479));
}

.vertical-slider .slick-prev::before {
    content: none;
}

.vertical-slider .slick-next::before {
    content: none;
}

.vertical-slider .slick-prev {
    left: 0 !important;
}

.vertical-slider .slick-next {
    right: 0 !important;
    top: auto;
}

.vertical-slider .slick-next:hover {
    color: #fff;
    outline: none;
    background: linear-gradient(to bottom, #fff, rgba(80, 92, 95, 0.479));
}

.vertical-slider .slick-prev:hover {
    color: #fff;
    outline: none;
    background: linear-gradient(to top, #fff, rgba(80, 92, 95, 0.479));
}

/* .slick-arrow.slick-prev,.slick-arrow.slick-next{
    background: #2c4152;
    width:45px;
    height: 45px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.slick-arrow.slick-prev:hover,.slick-arrow.slick-next:hover{
    background: #1976d2;
} */
/* .slick-prev{
    left: 10px !important;
}
.slick-next{
    right: 10px !important;
}
.slick-prev::before, .slick-next::before{
    display: none;
}
*/
.css-ohwavn .slick-arrow {
    display: none !important;
}

.slick-dots {
    bottom: 5px !important;
}

.slick-dots .slick-active button::before {
    /* color: #1976d2 !important; */
    color: #ff5501 !important;
}

.slick-dots li button::before {
    width: 20px !important;
    top: -6px !important;
    font-size: 10px !important;
    line-height: 10px !important;
    color: #a4a4a4 !important;
}

/* Loadig css */
.loader {
    width: 48px;
    height: 48px;
    background: #1976d2;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 6px;
    top: 10px;
    width: 12px;
    height: 12px;
    color: #fff;
    background: currentColor;
    border-radius: 50%;
    box-shadow: 25px 2px, 10px 22px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.feature>li {
    margin-top: 0.5rem;
}

.social {
    text-decoration: none;
    color: #fff;
    transition: 0.8s;
}

.social:hover {
    color: #3498db;
}

.help {
    text-decoration: none;
    color: #fff;
}

.help:hover {
    color: #3498db;
}

.aboutimg {
    transition: 0.5s;
    padding: 8px;
    margin-left: -8px;
}

.aboutimg:hover {
    background-color: #3498db8c;
}